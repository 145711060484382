<template>
  <v-container class="pa-0 fill-height" fluid>
    <v-card elevation="0" class="pa-5 fill-height" style="width: 100%">
      <v-card-text class="pa-0 fill-height">
        <v-row no-gutters class="fill-height flex-wrap flex-column">
          <v-col cols="auto" class="grow">
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-icon v-if="getMobileOperatingSystem() == 'iOS'" @click="openShareOptions()">mdi-export-variant</v-icon>
              <v-icon v-if="getMobileOperatingSystem() == 'Android'"
                @click="openShareOptions()">mdi-share-variant</v-icon>
            </v-row>
            <v-row no-gutters class="text-center pa-0">
              <v-col cols="12" class="pa-0">
                <v-img id="parkingimg" alt="PARKING.COM" class="my-3" height="42" contain
                  src="../assets/logo_huge_drk.png" />
              </v-col>
            </v-row>
            <v-row class="mt-8" no-gutters id="receipt">
              <v-col>
                <v-row class="receipt-content  font-weight-bold" no-gutters>
                  <v-col cols="7" class="pa-0 text-left">TOTAL PARKING CHARGES <sup>*</sup></v-col>
                  <v-col class="pa-0 text-right">$
                    {{
                       bookingTransactionHistory &&  checkValidValue(bookingDetails.transactionHistory.top["TOTAL PARKING CHARGES"])
                      ? bookingDetails.transactionHistory.top["TOTAL PARKING CHARGES"]
                      : ""
                    }}</v-col>
                </v-row>
                <v-row class="receipt-content " no-gutters>
                  <v-col class="pa-0 text-left pb-3" style="font-size:12px">
                    {{
                      bookingDetails.hasOwnProperty("booking") && bookingDetails.booking.hasOwnProperty("totalTax") && checkValidValue(bookingDetails.booking.totalTax)
                      ? 'Includes state and local taxes of ' + " $ " + bookingDetails.booking.totalTax
                      : ""
                    }}</v-col>
                </v-row>
                <v-row class="receipt-content py-1" no-gutters>
                  <v-col cols="7" class="pa-0 text-left grey--text text--darken-3">ENTERED AT</v-col>
                  <v-col class="pa-0 text-right grey--text text--darken-3">{{
                    bookingDetails.hasOwnProperty("booking") &&
                    bookingDetails.booking.hasOwnProperty("entrytime") && checkValidValue(bookingDetails.booking.entrytime)
                    ? formatDateTime(bookingDetails.booking.entrytime)
                    : ""
                  }}</v-col>
                </v-row>
                <v-row class="receipt-content py-1" no-gutters>
                  <v-col cols="7" class="pa-0 text-left grey--text text--darken-3">EXITED AT</v-col>
                  <v-col class="pa-0 text-right grey--text text--darken-3">{{
                    bookingDetails.hasOwnProperty("booking") &&
                    bookingDetails.booking.hasOwnProperty("exittime") && checkValidValue(bookingDetails.booking.exittime)
                    ? formatDateTime(bookingDetails.booking.exittime)
                    : ""
                  }}</v-col>
                </v-row>
                <v-row class="receipt-content py-1" no-gutters>
                  <v-col cols="7" class="pa-0 text-left grey--text text--darken-3">STAY DURATION</v-col>
                  <v-col class="pa-0 text-right grey--text text--darken-3">{{
                    bookingTransactionHistory &&  checkValidValue(bookingDetails.transactionHistory.top["STAY DURATION"])
                    ? bookingDetails.transactionHistory.top["STAY DURATION"]
                    : ""
                  }}</v-col>
                </v-row>
                <v-row class="receipt-content py-1" no-gutters>
                  <v-col cols="7" class="pa-0 text-left grey--text text--darken-3">GARAGE NAME</v-col>
                  <v-col class="pa-0 text-right grey--text text--darken-3">{{
                    bookingDetails.hasOwnProperty("zone") && bookingDetails.zone.hasOwnProperty('zoneName') && checkValidValue(bookingDetails.zone.zoneName)
                    ? bookingDetails.zone.zoneName
                    : ""
                  }}</v-col>
                </v-row>
                <v-row class="receipt-content py-1" no-gutters>
                  <v-col cols="7" class="pa-0 text-left grey--text text--darken-3">LOCATION</v-col>
                  <v-col class="pa-0 text-right grey--text text--darken-3">{{
                    bookingTransactionHistory &&  checkValidValue(bookingDetails.transactionHistory.top["LOCATION"])
                    ? bookingDetails.transactionHistory.top["LOCATION"]
                    : ""
                  }}</v-col>
                </v-row>
                <v-row class="receipt-content pb-2" no-gutters>
                  <v-col class="pa-0 text-right grey--text text--darken-3">{{
                     Object.hasOwnProperty.call(bookingDetails,
                      'zone') && Object.hasOwnProperty.call(bookingDetails.zone,
                        'mySpotSettings')
                        && checkValidValue(bookingDetails.zone.mySpotSettings) && Object.hasOwnProperty.call(bookingDetails.zone.mySpotSettings, 'primaryCustomerNumbers') &&

                    bookingDetails.zone.mySpotSettings.primaryCustomerNumbers.length > 0 && bookingDetails.zone.mySpotSettings.primaryCustomerNumbers[0] != undefined && bookingDetails.zone.mySpotSettings.primaryCustomerNumbers[0] != null
                    ? bookingDetails.zone.mySpotSettings.primaryCustomerNumbers[0]
                    : ""
                  }}</v-col>
                </v-row>
                <v-row class="receipt-content pb-2" no-gutters>
                  <v-col cols="7" class="pa-0 text-left grey--text text--darken-3">US CUSTOMER SUPPORT</v-col>
                  <v-col class="pa-0 text-right"><a class="grey--text  text--darken-3" :href="'tel:' + getUSCustomerCareNumber " style="text-decoration: none;">{{ getUSCustomerCareNumber }}</a></v-col>
                </v-row>
                <v-row class="receipt-content pb-2" no-gutters>
                  <v-col cols="7" class="pa-0 text-left grey--text text--darken-3">FOR CANADA AND INTERNATIONAL VISITORS</v-col>
                  <v-col class="pa-0 text-right align-self-center"><a class="grey--text text--darken-3" :href="'tel:' + getCanadaCustomerCareNumber" style="text-decoration: none;">{{ getCanadaCustomerCareNumber }}</a></v-col>
                </v-row>
                <v-row class="receipt-content py-1 " no-gutters>
                  <v-col class="pa-0 text-center text--caption text--darken-3">{{
                     Object.hasOwnProperty.call(bookingDetails,
                      'zone') && Object.hasOwnProperty.call(bookingDetails.zone,
                      'mySpotSettings') && checkValidValue(bookingDetails.zone.mySpotSettings) && Object.hasOwnProperty.call(bookingDetails.zone.mySpotSettings,
                      'parkingInstructions')  && checkValidValue(bookingDetails.zone.mySpotSettings.parkingInstructions) 
                    ? bookingDetails.zone.mySpotSettings.parkingInstructions
                    : ""
                  }}</v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mx-8 my-2"></v-divider>
            <v-row class="my-2" no-gutters>
              <v-col>
                <v-row class="receipt-content py-1" no-gutters>
                  <v-col cols="7" class="pa-0 text-left grey--text text--darken-3">ORDER NUMBER</v-col>
                  <v-col class="pa-0 text-right grey--text text--darken-3">{{
                     bookingTransactionHistory && checkValidValue(bookingDetails.transactionHistory.middle[
                    "BOOKING REFERENCE"
                    ])
                    ? bookingDetails.transactionHistory.middle[
                    "BOOKING REFERENCE"
                    ]
                    : ""
                  }}</v-col>
                </v-row>
                <!-- <v-row class="receipt-content py-1">
            <v-col cols="7" class="pa-0 text-left grey--text text--darken-3">TRANSACTION ID</v-col>
            <v-col class="pa-0 text-right grey--text text--darken-3">{{bookingDetails.hasOwnProperty("booking") ? bookingDetails.transactionHistory.middle["TRANSACTION ID"] : ""}}</v-col>
                </v-row>-->
                <v-row class="receipt-content py-1" no-gutters>
                  <v-col cols="7" class="pa-0 text-left grey--text text--darken-3">PAYMENT MODE</v-col>
                  <!-- <v-col class="pa-0 text-right grey--text text--darken-3" v-if="bookingDetails.booking.paymentType!='APPLE_PAY'">{{
                    bookingDetails.hasOwnProperty("booking")
                      ? bookingDetails.transactionHistory.middle["PAYMENT MODE"]
                      : ""
                  }}</v-col> -->
                  <v-col class="pa-0 text-right grey--text text--darken-3"
                    v-if="Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking, 'paymentType') && checkValidValue(bookingDetails.booking.paymentType)">
                    {{ getPaymentType(bookingDetails.booking.paymentType) }}
                  </v-col>
                </v-row>
                <v-row class="receipt-content py-1" no-gutters>
                  <v-col cols="7" class="pa-0 text-left grey--text text--darken-3">CREDIT CARD NUMBER</v-col>
                  <v-col class="pa-0 text-right grey--text text--darken-3">{{
                     bookingTransactionHistory && checkValidValue(bookingDetails.transactionHistory.middle["CREDIT CARD NUMBER"])
                    ? bookingDetails.transactionHistory.middle[
                    "CREDIT CARD NUMBER"
                    ]
                    : ""
                  }}</v-col>
                </v-row>
                <!-- <v-row class="receipt-content py-1">
            <v-col cols="7" class="pa-0 text-left grey--text text--darken-3">AUTHORIZATION CODE</v-col>
            <v-col class="pa-0 text-right grey--text text--darken-3">{{bookingDetails.hasOwnProperty("booking") ? bookingDetails.transactionHistory.middle["AUTHORIZATION CODE"] : ""}}</v-col>
                </v-row>-->
              </v-col>
            </v-row>
            <v-divider class="mx-8 my-2"></v-divider>
            <v-row class="my-2" no-gutters>
              <v-col>
                <v-row class="receipt-content py-1" no-gutters>
                  <v-col cols="7" class="pa-0 text-left grey--text text--darken-3">PHONE NUMBER</v-col>
                  <v-col class="pa-0 text-right">{{
                    bookingTransactionHistory &&  checkValidValue(bookingDetails.transactionHistory.bottom["PHONE NUMBER"])
                    ? bookingDetails.transactionHistory.bottom["PHONE NUMBER"]
                    : ""
                  }}</v-col>
                </v-row>
                <v-row class="receipt-content py-1" no-gutters>
                  <v-col cols="7" class="pa-0 text-left grey--text text--darken-3">DATE</v-col>
                  <v-col class="pa-0 text-right grey--text text--darken-3">{{
                    bookingTransactionHistory && checkValidValue(bookingDetails.transactionHistory.bottom["DATE"])
                    ? bookingDetails.transactionHistory.bottom["DATE"]
                    : ""
                  }}</v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="isPreAuthEnabled">
            <pre-auth-sheet style="border: 1px solid #FFEBEB;border-radius: 8px;font-size: 0.2px;" class="mb-4"></pre-auth-sheet>
          </v-col>
          <v-col cols="auto" class="flex-shrink-1 flex-grow-1">
            <v-btn rounded class="close_receipt_btn" elevation="20" dark @click="closeReceipt">CLOSE</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <v-card-actions class="grow">
        <v-btn rounded class="close_receipt_btn" elevation="0" dark @click="closeReceipt">CLOSE</v-btn>
      </v-card-actions>-->
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import APIHelper from "../apiHelper";
import PreAuthSheet from "@/uiComponents/PreAuthSheet.vue";
import { EventBus } from "@/lib/EventBus";
// import loggerHelper from "../loggerHelper";
export default {
  name: "Receipt",
  data: () => ({}),
  computed: {
    ...mapGetters({
      bookingId: "getterBookingId",
      bookingDetails: "getterBookingDetails",
    }),
    bookingTransactionHistory(){
     return  Object.hasOwnProperty.call(this.bookingDetails, 'transactionHistory') && (Object.hasOwnProperty.call(this.bookingDetails.transactionHistory , 'top') || Object.hasOwnProperty.call(this.bookingDetails.transactionHistory , 'bottom') && Object.hasOwnProperty.call(this.bookingDetails.transactionHistory,'middle'))
    },
    isPreAuthEnabled(){
      return Object.hasOwnProperty.call(this.bookingDetails,'zone') && Object.hasOwnProperty.call(this.bookingDetails?.zone , 'preAuth') && Object.hasOwnProperty.call(this.bookingDetails?.zone?.preAuth ,'enabled') &&this.bookingDetails?.zone?.preAuth?.enabled=='1' && (this.bookingDetails?.booking?.TypeOfBooking=='Guest' || this.bookingDetails?.booking?.TypeOfBooking=='Transient' || this.bookingDetails?.booking?.TypeOfBooking=='Self reservation' || (this.bookingDetails?.booking?.TypeOfBooking=='Ondemand' && this.bookingDetails?.zone?.ODChargeUpfront=='0') )  && this.bookingDetails?.booking?.eventModeTxn=='0' && this.bookingDetails?.booking?.entryMode!='CC' && this.bookingDetails?.booking?.payment_status!= 'FAILED'
    },
    getUSCustomerCareNumber(){
      return process.env.VUE_APP_US_CUSTOMER_CARE_NUM
    },
    getCanadaCustomerCareNumber(){
      return process.env.VUE_APP_CANADA_CUSTOMER_CARE_NUM
    },
  },
  components:{
    PreAuthSheet
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.$vuetify.goTo(0);
      if (vm.bookingId != null) {
        try {
          var bookingDetails = await APIHelper(
            "GET",
            "/api/v1/booking/config/" + vm.bookingId
          );
          vm.$store.commit(
            "SET_BOOKING_DETAILS",
            bookingDetails.data?.data ? bookingDetails.data.data : null
          );
        } catch (error) {
          console.log(error);
        }
      }
    });
  },
  methods: {
    getPaymentType(type) {
      switch (type) {
        case "APPLE_PAY": {
          return 'Paid with Apple Pay'
        }
        case "GOOGLE_PAY": {
          return "Paid with Google Pay"
        }
        case "Card": {
          return `${this.bookingDetails?.booking?.cardType
            ? this.bookingDetails.booking.cardType.toUpperCase()
            : ""} **** ${this.bookingDetails?.booking?.cardNo
            ? this.bookingDetails.booking.cardNo
            : ""}`
        }
      }
    },
    async closeReceipt() {
      // window.close();
      await this.$router.push({ path: "/checkout" });
      if(this.bookingDetails?.booking?.autoP2PDetails)
             {
              EventBus.$emit('AUTO_P2P_BID', this.bookingDetails)
             }

      EventBus.$emit('SET_FREE_BOOKING')
    },
    formatDateTime(time) {
      // Jan 15, 01:21 AM
      let utcDate = new Date(time * 1000).toUTCString(); //"Wed, 27 Jan 2021 13:59:04 GMT"
      let month = utcDate.split(",")[1].split(" ")[2]; // Jan
      let date = utcDate.split(",")[1].split(" ")[1]; // 27
      let hr = utcDate.split(",")[1].split(" ")[4].split(":")[0]; // 13
      let min = utcDate.split(",")[1].split(" ")[4].split(":")[1]; //59
      let formatHr = Number(hr) > 12 ? Number(hr) - 12 : Number(hr); // 1
      formatHr = Number(formatHr) >= 10 ? Number(formatHr) : "0" + formatHr; //01
      let amOrpm = Number(hr) >= 12 ? "PM" : "AM"; //PM
      return month + " " + date + ", " + formatHr + ":" + min + " " + amOrpm; //Jan 27, 01:59 PM
    },
    /**
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
     *
     * @returns {String}
     */
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      return "unknown";
    },
    dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      //Old Code
      //write the ArrayBuffer to a blob, and you're done
      //var bb = new BlobBuilder();
      //bb.append(ab);
      //return bb.getBlob(mimeString);

      //New Code
      return new Blob([ab], { type: mimeString });
    },
    openShareOptions() {
      this.postToLoggerAPI({
        phone: this.bookingDetails.user.contact,
        refId: this.bookingId,
        statusText: "Parker clicked on receipt share option.",
      })
      var self = this;
      if (navigator.canShare) {
        navigator
          .share({
            url: window.location.href,
            title: "Parking.com - Guest Parking",
            text:
              "Here is the receipt for your parking at " +
              this.bookingDetails.zone.zoneName +
              " on " +
              this.bookingDetails.transactionHistory.bottom["DATE"] +
              " from" +
              this.bookingDetails.booking.jsonArr["Entered At"].split(",")[1] +
              " to" +
              this.bookingDetails.booking.jsonArr["Exited At"].split(",")[1] +
              ". ",
          })
          .then(() => {
            console.log("Share was successful.");
            this.postToLoggerAPI({
              phone: self.bookingDetails.user.contact,
              refId: self.bookingId,
              statusText: "Shared receipt successfully",
            })
          })
          .catch((error) => {
            console.log("Sharing failed", error);
            this.postToLoggerAPI({
              phone: self.bookingDetails.user.contact,
              refId: self.bookingId,
              error: error,
              statusText: "Error in sharing the receipt",
            })
          });
      } else {
        this.postToLoggerAPI({
          phone: self.bookingDetails.user.contact,
          refId: self.bookingId,
          statusText: "Parker system does not support sharing files",
        })
        console.log("Your system does not support sharing files.");
      }
      // });
    },
  },
};
</script>
<style lang="less">
.close_receipt_btn {
  background-color: #f2555c !important;
  width: 100%;
  font-size: 18px !important;
  font-weight: 700 !important;
  height: 50px !important;
  letter-spacing: normal !important;
}

.receipt-content {
  font-size: 14px;
}</style>
